
.ButtonIC {
    height: 32px ;
    width: 84px ;
    background: #9ECF13;
    border: #9ECF13;
    color: #fff;
    border-radius: 5px;
    font-size: 12pt;
}

.ButtonIC:hover{
    opacity: 1;
}


.ButtonFilterIC {
    height: 32px ;
    width: 115px ;
    /*//margin-left: 27px;*/
    background: #5A6963;
    border: #9ECF13;
    color: #fff;
    font-size: 9px;
    border-radius: 5px;
}
