@import url("./variables.css");
@import url("./assets/css/styleDatePicker.css");

html,
body {
  font-family: "Poppins", sans-serif;
  color: #5a6963;
  overflow-x: hidden;
  text-transform: uppercase;
}

.p-relative {
  position: relative !important;
}

html {
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
}

.w-10 {
  width: 10rem !important;
}

.w-15 {
  width: 15%;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.white-space-normal {
  white-space: normal !important;
}

.overflowx_auto {
  overflow-x: auto;
}

.table_responsive {
  overflow-y: visible !important;
}

.btn {
  cursor: pointer !important;
}

.bold {
  font-weight: bold;
}

.border-none {
  border: none !important;
}

.btn[disabled] {
  cursor: not-allowed !important;
}

.mt-6rem {
  margin-top: 4.3rem;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.dropdown-item {
  line-height: 2 !important;
}

/* Tirar setas dos inputs */
/* input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
} */

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.botao_padrao {
  background: var(--cor_padrao_site) 0% 0% no-repeat padding-box;
  border: none;
  padding: 0.4rem;
  color: #fff;
  border-radius: 5px;
}

.botao_padrao:hover,
.botao_padrao:active,
.botao_padrao:focus {
  background-color: var(--cor_padrao_site) !important;
  border-color: none;
}

.botao_padrao:active {
  box-shadow: 0px 0px 0px 1px #5a6963;
}

.botao_padrao:disabled {
  opacity: 0.8;
  cursor: wait;
}

.botao_padrao:focus-visible {
  outline: none !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.titulo_principal {
  color: #5a6963;
}

.label_input {
  font-size: 0.8rem;
  color: #5a6963;
  font-weight: 500;
}

.input {
  border-radius: 8px !important;
}

.acoes {
  overflow: initial !important;
}

.item_breadcrumbs {
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  font-family: "Poppins";
}

.MuiCardHeader-title {
  font-size: 19px !important;
  font-weight: 500 !important;
  color: #5a6963 !important;
  font-family: "Poppins" !important;
}

.title_section {
  margin-bottom: 7px;
  font-weight: 500;
  font-size: 14px;
  color: #5a6963 !important;
  font-family: "Poppins" !important;
}

.MuiCard-root {
  overflow-y: auto !important;
}

.card_default {
  height: calc(100% - 100px) !important;
}

.item_breadcrumbs {
  margin-bottom: 0 !important;
}

.form-label {
  color: #5a6963 !important;
  font-family: "Poppins" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.form-control {
  border-radius: 10px !important;
  font-size: 12px;
}

.button {
  padding: 10px 15px 10px 15px;
  background: #9ecf13;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer !important;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
}

.button[disabled] {
  opacity: 0.5;
  cursor: no-drop !important;
}

.button_cancel {
  color: red;
  width: 6rem;
  background: white;
  border: 1px solid;
}

.button_clean {
  color: #ffbf26;
  width: 6rem;
  background-color: white;
  border: 1px solid;
}

.button_filter {
  width: 6rem;
  margin-top: 1.7rem;
}

.buttons_actions_form {
  position: absolute;
  bottom: 90px;
  right: 70px;
}

.buttons_action div:first-child {
  display: flex;
  float: right;
  gap: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
}

.button_form {
  padding-right: 0;
}

.container_card_form {
  border-collapse: collapse;
  display: table;
}

.buttons_action {
  display: table-row;
  vertical-align: bottom;
  height: 1px;
  border: none !important;
}

.row_actions {
  margin-bottom: 1rem;
  margin-right: 2rem;
}

.data_grid_table {
  height: 5350px;
}

.data_grid_table.height_initial {
  height: 400px;
}

@media (min-height: 900px) {
  .data_grid_table.height_initial {
    height: 590px;
  }
}

@media (min-height: 900px) {
  .image_guide {
    width: 220px !important;
  }
}

@media (max-width: 540px) {
  .card_default {
    height: 80vh;
  }
}

@media (max-width: 405px) {
  .button_form {
    padding: initial !important;
  }

  .MuiCardHeader-root {
    display: block !important;
  }

  .MuiCardHeader-action {
    margin-top: 10px !important;
  }
}

.divider {
  border-top: 3px solid #9ecf13;
  margin: 0;
  width: 110px;
}

.divider_bottom {
  margin: 0;
}

.cell_options {
  overflow: inherit !important;
}

.icone_acoes button.dropdown-toggle::before {
  display: initial;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: initial !important;
  border-right: initial !important;
  border-bottom: initial !important;
}

.icone_acoes button:active,
.icone_acoes button:focus,
.icone_acoes button:focus-visible {
  box-shadow: none !important;
}

.MuiDataGrid-overlay {
  top: 10rem !important;
  align-items: initial !important;
}

.MuiCircularProgress-indeterminate {
  color: #9ecf13 !important;
  z-index: 3;
}

.title_modal_delete {
  color: #5a6963 !important;
  margin-top: 9px !important;
  font-size: 15px !important;
  font-family: "Poppins" !important;
}

.container_modal_delete {
  border-radius: 20px !important;
  width: 400px !important;
  height: 8rem !important;
}

.button_modal_delete {
  display: inline-block;
  width: 82px;
  height: 42px;
  font-size: 12px !important;
  text-transform: uppercase !important;
  font-family: "Poppins" !important;
}

.button_modal_delete.not {
  margin-right: 20px !important;
  background: white !important;
  color: red !important;
  border: 1px solid !important;
}

.button_modal_delete.yes {
  background-color: rgb(158, 207, 19);
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_modal_delete:focus {
  box-shadow: none !important;
}

.card_form {
  height: calc(100% - 100px);
  display: block;
  overflow-x: hidden;
}

.error_validation {
  color: red;
  font-size: 11px;
  font-family: "Poppins";
  font-weight: 500;
  text-align: left;
  margin: 0.3rem;
}

@media (max-width: 600px) {
  .Arrows {
    max-width: 50vh;
    justify-content: inherit !important;
    overflow-y: hidden;
  }

  .Arrows--step {
    min-width: 130px;
  }
}

.uploadFoto {
  min-height: 200px !important;
}

.d-flex {
  display: flex !important;
}

.justify-content-right {
  justify-content: right;
}

.justify-space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.hidden_footer_table .MuiDataGrid-footerContainer {
  display: none !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.expand_card {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 200000;
  height: 100% !important;
}

@media (min-width: 1500px) {
  .expand_card .data_grid_table {
    height: 73vh;
  }
}

@media (min-width: 1900px) {
  .expand_card .data_grid_table {
    height: 80vh;
  }
}

.label_reserve_management {
  white-space: nowrap;
  font-size: 13px !important;
}

.carrinhoProdutos {
  background-color: #ffffff;
  font-size: 15px;
}

.btnRemove {
  margin-left: 3px;
  background-color: #cf3913;
  border: none;
  color: #fff;
  border-radius: 5px;
  height: 27px;
  text-align: center;
}

.font-family-poppins {
  font-family: "Poppins";
}

.button_action_purchase {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_action_purchase.remove {
  background-color: #cf3913;
}

.button_action_purchase.edit {
  background-color: #ffbf26;
}

.modal_select_tab {
  z-index: 4000;
}

.modal_select_tab .modal-header .close {
  opacity: 1;
}

.field-reserve-actions .btn {
  padding: initial;
  font-size: initial;
}

.animatin-open-list {
  display: block;
  animation: show-up 0.3s forwards;
}

@keyframes show-up {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

* {
  text-transform: uppercase;
}

.documents_permission_record label {
  min-height: 220px;
}

.detalhar-documentacao,
.swal2-container {
  z-index: 6000 !important;
}

.filtro-modal {
  z-index: 200000;
}

.image_guide {
  width: 150px;
  height: 200px;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  cursor: pointer;
}

.modal-content_1 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 70%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal {
  position: fixed;
  z-index: 2000;
}

.MuiDataGrid-viewport {
  overflow: initial !important;
}

.days-reserve {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  float: left;
  width: 100%;
  margin-top: 15px;
}

.messege-days {
  float: left;
  font-size: 11px;
  margin-top: -15px;
  font-size: italic;
  text-transform: lowercase;
}

.days-reserve .form-group {
  text-align: center;
}

.modal-vehicle .modal-content {
  margin-left: 10px;
  margin-right: 20px;
}

.vehicle-value-fieldset {
  margin-top: 3px;
}

#inputEmail {
  text-transform: none !important;
}

.imgStyle {
  margin-top: 5px;
  margin-left: 10px;
}


.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.checkbox-md input {
  width: 18px;
  height: 20px;
}