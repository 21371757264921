.title {
  margin-top: 30px;
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins";
  color: #828282;
}

.app-logo {
  margin-bottom: 10px;
}

.app-main {
  background-color: #f2f4f8;
  min-width: auto;
  height: auto;
  width: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.div-content {
  max-width: 378px !important;
  height: 300px !important;
  box-shadow: 1px 1px 12px 6px #090e2518;
  border: 1px solid #d8e1ec;
  border-radius: 5px;
  opacity: 1;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 25px;
  padding-right: 25px;
}

.div-debug {
  max-width: 378px !important;
  /* height: 300px !important; */
  box-shadow: 1px 1px 12px 6px #090e2518;
  border: 1px solid #d8e1ec;
  border-radius: 5px;
  opacity: 1;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 25px;
  padding-right: 25px;
}

.edtUser {
  height: 2rem !important;
  margin-bottom: 1.0625rem !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e4e9f0;
  border-radius: 4px !important;
  opacity: 1;
  text-align: left;
  font: normal normal normal 11px/17px Poppins;
  letter-spacing: 0px;
  color: #a1a1c2;
  opacity: 1;
  text-transform: initial !important;
}

.edtUser::placeholder {
  color: #a1a1c2 !important;
}

.edtPassword {
  width: 20.1875rem;
  height: 1.75rem !important;
}

.button-login {
  line-height: 1;
  width: 100% !important;
}

.button-login:disabled {
  opacity: 0.5 !important;
  background: #83b100 !important;
}

.button-login:hover,
.button-login:focus,
.button-login:active {
  background-color: #83b100 !important;
  box-shadow: initial !important;
  border-color: initial !important;
}

.forget_password {
  color: #83b100;
  font-size: 11px;
  margin-top: 20px;
  font-family: "Poppins";
  font-weight: 500;
  display: block;
}

.forget_password:hover {
  color: #9ecf13 !important;
  text-decoration: none;
}

.label_logo {
  font-size: 13px;
  text-align: left;
  font-family: "Poppins";
  font-weight: 500;
  letter-spacing: 0px;
  color: #5a6963;
  opacity: 1;
}

.tiny {
  font-size: 10px;
}

/* IPHONE 5 */
@media screen and (device-aspect-ratio: 40/71) {
  .div-content {
    min-width: 300px !important;
  }
}
