.react-datepicker__header {
  background-color: #9ecf13 !important;
}

.react-datepicker__header .react-datepicker__day-name {
  color: white !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #fff !important;
  padding: 18px 25px !important;
}

.react-datepicker__day--selected {
  background-color: #54c525 !important;
  border-radius: 50px !important;
}

.react-datepicker__input-container input {
  padding: 0.4rem;
  border-radius: 6px;
  border: 1px solid #ced4da;
}

.react-datepicker__input-container input:focus-visible {
  border: 1px solid red;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.react-datepicker__day--outside-month {
  color: #cdcbcb !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #54c525 !important;
  border-radius: 50px !important;
  font-size: 14px;
}

.react-datepicker__navigation-icon::before {
  border-color: #f8f9fa;
}
