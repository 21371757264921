.component_navbar .navbar_img_profile {
  width: 39px;
  height: 39px;
  position: absolute;
  right: 20px;
  border-radius: 5px;
  background-size: cover;
  background-image: url("https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;h=750&amp;w=1260");
  background-repeat: no-repeat;
}

.component_navbar .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  margin: 10px;
  background: #495450;
  color: white;
}

.component_navbar .MuiAccordionSummary-expandIconWrapper {
  color: white;
}

.component_navbar .item_menu_navbar {
  margin: 10px;
  padding: 12px;
  display: block;
  color: white !important;
  text-decoration: none;
  border-radius: 5px;
  background: #495450;
}

.component_navbar .css-ivnu1y-MuiPaper-root-MuiAppBar-root {
  background-color: white;
}

.component_navbar .item_menu_navbar.active,
.component_navbar .children_menu_navbar.active,
.component_navbar .active.cursor_pointer:hover,
.component_navbar .children_menu_navbar:hover {
  color: #9ecf13 !important;
}

.component_navbar .children_menu_navbar {
  margin-left: 20px;
  font-size: 14px;
  cursor: pointer;
  display: block;
  padding-top: 5px;
  text-decoration: none;
  color: white !important;
  padding-bottom: 5px;
}

.component_navbar .content {
  background: #f2f4f8;
  position: absolute;
  left: 269px;
  right: 0;
  bottom: 0;
  top: 0;
}

.component_navbar .icon_menu_navbar {
  float: right;
  font-size: 20px;
}

.component_navbar .MuiPaper-elevation0 {
  color: white !important;
  font-family: "Poppins", sans-serif;
  background: #5a6963 !important;
}

.component_navbar .iconGroupHeader {
  display: flex;
  position: absolute;
  right: 20px;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #919191;
}

*::-webkit-scrollbar-thumb {
  background-color: #495450;
  border-radius: 20px;
  border: 2px solid #919191;
}

@media (max-width: 900px) {
  .component_navbar .content {
    left: 0;
    /* bottom: initial; */
  }
}
